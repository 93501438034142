<template>
  <div class="mx-3 mt-3" style="min-height: 690px">
    <h1 class="text-xl text-left font-bold flex mb-2 ">
      Admins
    </h1>
    <div class="border mb-2" />
    <Admins />
  </div>
</template>

<script>
import Admins from "./admins";

export default {
  name: "Admin",
  components: {
    Admins,
  },
};
</script>

<style></style>
