<template>
  <div class=" mt-6">
    <div v-if="!showError">
    <Card class="mt-6 p-5 relative">
      <div class="flex justify-between items-center gap-6 mb-6">
        <div style="width: 585px;">
          <p>Admins are account users who may not necessarily be employees. To add a new admin, select an existing employee or add a new user and assign a role based on your preferred privileges.</p>
        </div>
        <Button
          options
          :option-list="['From Employee/People', 'Create New']"
          class="bg-dynamicBackBtn text-white"
          width="auto"
          style-name="width:-webkit-fill-available"
          @click="checkEmployee($event)"
        >
          <span class="inline-block w-28 h-11">New Admin</span>
        </Button>
      </div>
      <template v-slot:footer>
        <card-footer
          @sortType="handleSort($event)"
          @searchResult="handleSearch($event)"
        />
      </template>
    </Card>

    <sTable
      :headers="adminsHeader"
      :items="admins"
      style="width:100%"
      aria-label="user table"
      class="w-full"
      has-number
      :pagination-list="metaData"
      @page="handlePage($event)"
      @itemsPerPage="handleItemsPerPage($event)"
      page-sync
      :loading="loading"
    >
      <template v-slot:item="{ item }">
        <div v-if="item.fname" class="flex">
          <span>
            <img
              class=""
              :src="item.data.photo"
              v-if="item.data.photo"
              alt="profile pic"
              style="height: 30px; width: 30px; border-radius: 5px"
            />
            <div
              style="height: 35px; width: 35px; border-radius: 5px;"
              class="text-blueCrayola border text-center font-semibold pt-2"
              v-else
            >
              {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
            </div>
          </span>
          <span
            class="font-bold cursor-pointer mt-2 ml-4"
            @click="viewEmployeeDetails(item.data.userId)"
            style="color: #525252"
            >{{ item.data.fname }} {{ item.data.lname }}</span
          >
        </div>
        <span v-else-if="item.roles" class="text-center">
          {{ item.data.roles.length ? item.data.roles[0].name : 'Owner' }}
        </span>
        <span v-else-if="item.isEmployee" class="text-center">
          {{ item.data.isEmployee ? "Employee" : "Non-Employee" }}
        </span>
        <span v-else-if="item.orgFunction" class="text-center">
          {{ !item.data.orgFunction ? "-" : item.data.orgFunction.name }}
        </span>
        <span v-else-if="item.auth">
          <p v-if="item.data.auth && item.data.auth.lastLogin === null">
            --
          </p>
          <p v-if="item.data.auth && item.data.auth.lastLogin !== null">
            {{
              $DATEFORMAT(new Date(item.data.auth.lastLogin), "MMMM dd, yyyy")
            }}
          </p>
          <p
            v-if="item.data.auth && item.data.auth.lastLogin !== null"
            class="text-xs text-blueCrayola"
          >
            {{ $DATEFORMAT(new Date(item.data.auth.lastLogin), "hh:mm a") }}
          </p>
        </span>
        <span v-else-if="item.id">
          <Menu left top="-150" margin="24" class="my-2 p-0">
            <template v-slot:title>
              <icon icon-name="more_icon" size="xs" />
            </template>
            <div style="width: 160px; padding: 10px 5px">
              <div
                @click="handleEdit(item.data)"
                class="flex  py-1 px-3 more h-8 cursor-pointer"
              >
                <icon
                  icon-name="edit"
                  :class-name="`text-blueCrayola mr-2`"
                  size="xs"
                />
                <p class="pt-1 text-sm">Edit</p>
              </div>
              <div
                @click="confirmDeleteUser(item.data.id)"
                v-if="!item.data.isRoot"
                class="flex  py-1 px-3 more h-8 cursor-pointer"
              >
                <icon
                  icon-name="delete"
                  :class-name="`text-flame mr-2`"
                  size="xs"
                />
                <p class="pt-1 text-sm">Delete</p>
              </div>
            </div>
          </Menu>
        </span>
      </template>
    </sTable>
    </div>
    <ErrorPage v-else />
    <Modal v-if="dialogDel">
      <Card class=" p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold  ml-4 my-5 text-center">
            Are you sure you want to delete this User?
          </p>
          <div class="flex ">
            <Button class="bg-dynamicBackBtn text-white" @click="deleteUser">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>

    <RightSideBar
      v-if="openEmployee"
      @close="openEmployee = false"
      submit="Proceed"
      @submit="handleSubmit"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          Add Admins from Employees/People Register
        </p>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col w-full mb-5">
          <p class="my-2 text-base">
            Search existing employee register to add as a user.
          </p>

          <div class="w-full mb-4">
            <Alert
              message="Employees, by default have access to
                 Cloudenly’s ESS. Once added to the user register,
                  they will be able to access Cloudenly’s Admin section, subject to role type."
              variant="primary"
              :time="2"
              style="position: relative; right: 0; top: 10px;
              background: rgba(244, 213, 176, 0.15); border: 1px solid #E99323;"
            />
          </div>
        </div>
      </template>

      <search-input class="my-4" @input="startSearch" placeholder="Search" />

      <div style="height:100%" v-if="loadingBar">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <div
          v-for="(item, index) in showMore ? employees : employees.slice(0, 6)"
          :key="index"
          class="flex flex-col py-4 userContainer border-b border-dashed"
        >
          <div class="flex items-center">
            <div>
              <img
                class="mr-3"
                :src="item.photo"
                v-if="item.photo"
                alt="user photo"
                style="height: 35px; width: 35px; border-radius: 5px"
              />
              <div
                style="height: 35px; width: 35px; border-radius: 5px;"
                class="text-blueCrayola mr-3 flex justify-center
               border text-center font-semibold pt-1"
                v-else
              >
                {{ $getInitials(`${item.fname} ${item.lname}`) }}
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <p>
                {{ `${item.fname} ${item.lname}` }}
              </p>
              <div
                class="text-xs flex font-semibold w- text-romanSilver  uppercase "
              >
                <p class="opacity-50 mr-2" style="font-size: 10px">
                  {{ `${item.orgFunction ? item.orgFunction.name : "---"}` }}
                </p>
                <p class="opacity-50 ml-1" style="font-size: 10px">
                  <span
                    class="dot dot-circle secondary"
                    style="width: 6px; height: 6px"
                  />
                </p>
                <p class="opacity-50 ml-2" style="font-size: 10px">
                  {{
                    `${
                      item.userDesignation ? item.userDesignation.name : "---"
                    }`
                  }}
                </p>
              </div>
            </div>
            <input
              type="radio"
              name=""
              :value="[item]"
              v-model="selectedItem"
              style="cursor: pointer;"
            />
          </div>
        </div>
        <p
          class=" text-flame ml-4 mt-10 cursor-pointer"
          @click="showMore = !showMore"
        >
          {{ showMore ? "Show Less" : "Show All" }}
        </p>
      </div>
    </RightSideBar>

    <RightSideBar
      v-if="assignRole"
      @close="closeAssignRole"
      submit="Save"
      @submit="assignRoleSubmit"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <div class="w-full flex">
          <back-button
            label=""
            @onClick="closeAssignRole"
            variant="secondary"
          />
          <p class="text-darkPurple mt-3">
            Assign Role
          </p>
        </div>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col w-full mb-5">
          <p class="my-2 text-base">
            Assign New Role to Employee.
          </p>
        </div>
      </template>

      <div>
        <div
          v-for="(item, index) in selectedItem"
          :key="index"
          class="flex flex-col py-4 userContainer"
        >
          <div class="flex items-center">
            <div>
              <img
                class="mr-3"
                :src="item.photo"
                v-if="item.photo"
                alt="user photo"
                style="height: 35px; width: 35px; border-radius: 5px"
              />
              <div
                style="height: 35px; width: 35px; border-radius: 5px;"
                class="text-blueCrayola mr-3 flex justify-center
               border text-center font-semibold pt-1"
                v-else
              >
                {{ $getInitials(`${item.fname} ${item.lname}`) }}
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <p>
                {{ `${item.fname} ${item.lname}` }}
              </p>
              <div
                class="text-xs flex font-semibold w- text-romanSilver  uppercase "
              >
                <p class="opacity-50 mr-2" style="font-size: 10px">
                  {{ `${item.orgFunction ? item.orgFunction.name : "---"}` }}
                </p>
                <p class="opacity-50 ml-1" style="font-size: 10px">
                  <span
                    class="dot dot-circle secondary"
                    style="width: 6px; height: 6px"
                  />
                </p>
                <p class="opacity-50 ml-2" style="font-size: 10px">
                  {{
                    `${
                      item.userDesignation ? item.userDesignation.name : "---"
                    }`
                  }}
                </p>
              </div>
            </div>
          </div>
          <c-select
            placeholder="--Select Role--"
            :options="roleSelect"
            variant="w-full"
            class="mt-3 w-full"
            v-model="roleId"
          />
        </div>
      </div>
    </RightSideBar>
    <create-people
      :open-modal="openModal"
      :title="editMode ? 'Edit Admin' : 'Create New Admin'"
      :is-edit="editMode"
      :clear-data="clearData"
      from="admin"
      :people-data="peopleDetails"
      @submit="handleDataSubmit($event)"
      @close="(openModal = false), (clearData = !clearData)"
    />
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import ErrorPage from "@/modules/Admin/error403";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";

export default {
  name: "Admins",
  components: {
    Button,
    Alert,
    BackButton,
    SearchInput,
    ErrorPage,
    Card,
    CardFooter,
    STable,
    CSelect,
    RightSideBar,
    Modal,
    Menu,
    CreatePeople: () => import("@/components/CreatePeople")
  },
  data() {
    return {
      openEmployee: false,
      openModal: false,
      editMode: false,
      clearData: false,
      peopleDetails: null,
      userDetails: null,
      admins: [],
      metaData: {},
      selectedItem: [],
      showError: false,
      roleSelect: [],
      roleId: "",
      loading: true,
      userId: null,
      dialogDel: false,
      adminsHeader: [
        { title: "NAME", value: "fname", width: "25" },
        { title: "ROLE", value: "roles", width: "20" },
        { title: "EMPLOYMENT", value: "isEmployee", width: "20" },
        { title: "FUNCTION", value: "orgFunction", width: "20" },
        { title: "LAST LOGIN", value: "auth", width: "20" },
        { title: "", value: "id", image: true }
      ],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      sortType: null,
      employees: [],
      pagination: "",
      paginationNumber: 1,
      showMore: false,
      loadingBar: true,
      assignRole: false
    };
  },

  methods: {
    handleSubmit() {
      if (this.selectedItem.length > 0) {
        this.assignRole = true;
        this.openEmployee = false;
      } else {
        this.$toasted.error("Please select an employee to proceed", {
          duration: 5000
        });
      }
    },

    async checkEmployee(optionSelected) {
      try {
        await this.$handlePrivilege("usersHR", "createNewUser");
        try {
          await this.$CanAddEmployees();
          this.handleClick(optionSelected);
        } catch (error) {
          this.$toasted.error(
            "You have used up the number of employees/admins subscribed for. Please contact your admin",
            {
              duration: 5000,
              action: {
                text: `${this.$AuthUser.isRoot ? "Manage Subscription" : ""}`,
                target: "_blank",
                href: `${process.env.VUE_APP_CLOUDENLY}management`
              }
            }
          );
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    handleClick(optionSelected) {
      if (optionSelected === "Create New") {
        this.editMode = false;
        this.openModal = true;
      } else {
        this.openEmployee = true;
      }
    },
    async handleEdit(data) {
      try {
        await this.$handlePrivilege("usersHR", "editUser");
        this.peopleDetails = null;
        this.clearData = false;
        this.peopleDetails = data;
        this.openModal = true;
        this.editMode = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    handleDataSubmit(data) {
      this.userDetails = data;
      if (this.editMode) {
        this.updateUser();
      } else {
        this.submit();
      }
    },
    submit() {
      const payLoad = this.userDetails;
      this.$_createUser(payLoad)
        .then(result => {
          this.$toasted.success(`${result.data.message}`, { duration: 5000 });
          this.getUsers("");
          this.openModal = false;
        })
        .catch((err) => {
          this.$toasted.error(`${err.message}`, { duration: 5000 });
        });
    },
    async updateUser() {
      try {
        await this.$_updateUser({
          payload: this.userDetails,
          userId: this.userDetails.id
        });
        this.$toasted.success("User updated successfully", { duration: 5000 });
        this.getUsers("");
        this.openModal = false;
      } catch (error) {
        this.$toasted.error(`${error.message}`, {
          duration: 5000
        });
        throw new Error(error);
      }
    },

    async confirmDeleteUser(id) {
      try {
        await this.$handlePrivilege("usersHR", "deleteUser");
        this.userId = id;
        this.dialogDel = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    deleteUser() {
      this.dialogDel = false;
      this.loading = true;
      this.$_updateUser({
        payload: {
          role: {
            appSlug: "humanar",
            roleId: null
          }
        },
        userId: this.userId
      }).then(() => {
        this.getUsers("");
      });
    },
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getUsers(`${sort}${search}${pageNumber}${itemPage}`);
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    getUsers(query) {
      this.loading = true;
      this.$_getAllOrgUsersWithOptions(`app=humanar&${query}`).then(
        ({ data }) => {
          this.admins = data.users.filter(user => user.isActive);
          this.metaData = data.meta;
          this.loading = false;
        }
      );
    },

    getEmployees(searchQuery) {
      this.$_getOrgPeople(searchQuery).then(result => {
        this.employees = result.data;
        this.loadingBar = false;
      });
    },

    startSearch(value) {
      this.loadingBar = true;
      clearTimeout(this.timeout);
      if (!value) {
        this.$_getOrgPeople("");
        return;
      }
      this.timeout = setTimeout(() => {
        this.$_getOrgPeople(value).then(result => {
          if (!result.data.length) {
            this.admins = [];
            this.loadingBar = false;
            return;
          }
          this.employees = result.data;
          this.loadingBar = false;
        });
      }, 500);
    },

    getHumanarRoles() {
      this.$_NewGetOrgRoles().then(result => {
        const orgRoles = result.data.roles;
        orgRoles.push({ id: "null", name: "None" });
        this.roleSelect = orgRoles.map(v => ({ id: v.id, name: v.name }));
      });
    },

    assignRoleSubmit() {
      if (this.roleId === "") {
        this.$toasted.error("Please assign Role to employee", {
          duration: 5000
        });
      } else {
        const payload = {
          role: {
            appSlug: "humanar",
            roleId: this.roleId === "null" ? null : this.roleId
          }
        };

        let userId = "";

        this.selectedItem.map(selected => {
          userId = selected.id;
          return {};
        });

        this.$_updateUser({ payload, userId })
          .then(() => {
            this.$toasted.success("User created successfully", {
              duration: 5000
            });
            this.closeAssignRole();
            this.getUsers("");
          })
          .catch(err => {
            this.$toasted.error("User cannot be created", { duration: 5000 });
            throw new Error(err);
          });
      }
    },

    closeAssignRole() {
      this.selectedItem = [];
      this.roleId = "";
      this.assignRole = false;
      this.employees = [];
      this.openEmployee = true;
      this.getEmployees("");
    }
  },
  async mounted() {
    try {
      await this.$handlePrivilege("usersHR", "accessUsersSettings");
      this.getUsers("");
      this.getHumanarRoles();
      this.getEmployees("");
      this.showError = false
    } catch (error) {
      this.loading = false
      this.showError = true
    }
  }
};
</script>

<style></style>
